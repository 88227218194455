<template>
  <div class="container-fluid pb-3" style="margin-top:15px">
  <vue-progress-bar></vue-progress-bar>
  <template v-if="elearningList"> <!-- && this.granted_elearning == 1 -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card-deck">
          <template v-for="item in elearningList">
          <div :key="item.uuid" class="card card--flex card-stats shadow-sm">
            <div class="card-header">
              <div class="card-icon bg-danger shadow">
                <i class="fe fe-play h2"></i>
              </div>
              <h3 class="card-title">{{ item.title }}</h3>
            </div>
            <div class="card-body d-flex flex-column">
              <p class="mt-auto"><i class="fe fe-info mr-2"></i>{{ item.description }}</p>
            </div>
            <div class="card-footer">
              <div class="stats">
                <router-link tag='span' :to='showElearningItem(item.uuid)' style='cursor:pointer;' class="btn btn-primary btn-block"><i class="fe fe-external-link mr-2"></i>Pokaż szkolenie</router-link>
              </div>
            </div>
          </div>
          </template>
        </div><!-- /card-deck -->
      </div><!-- /col -->
    </div><!-- /row -->
  </template>
  <template v-else>
    <div v-if="hasError" class="alert alert-warning alert--grouped">
      <i class="fe fe-alert-triangle mr-2"></i>
      Uwaga!<br/>Zasób nie istnieje lub nie masz do niego dostępu.
    </div>
    <div v-else class="loader"></div>
  </template>
  <template>
    <div v-if="totalItems == 0" class="alert alert-info alert--grouped mt-3">
      <i class="fe fe-alert-triangle mr-2"></i>
      Brak elementów do wyświetlenia.
    </div>
  </template>
  </div><!-- /container-fluid -->
</template>

<script>
// import jwtDecode from 'jwt-decode';
import { mapState } from 'vuex';
export default {
  data: function() {
    return {
      // granted_elearning: 0,
      hasError: false,
    };
  },
  created: function() {
    // const token = localStorage.getItem('token');
    // const decoded = token !== null ? jwtDecode(token) : null;

    // if (decoded !== null) {
    //   if (decoded.roles.includes('ROLE_AS_ELEARNING')) {
    //     this.granted_elearning = 1;
    //   } else {
    //     this.granted_elearning = 0;
    //   }
    // } else {
    //   this.$router.push('login');
    // }
    this.getDataOnCreation();
  },
  computed: {
    ...mapState('elearning', ['totalItems', 'elearningList']),
    // allowToShow: function() {
    //   return this.granted_elearning === 1;
    // },
  },
  methods: {
    getDataOnCreation: function() {
      this.$Progress.start();
      this.$store.dispatch('elearning/getElearning')
      .then(()=>this.$Progress.finish())
      .catch(error=>{
        this.$Progress.fail();
        this.hasError = true;
        console.error('Error:', error);
      })
    },
    showElearningItem(id) {
      return `/elearning/show/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";
</style>
